import { template as template_e6bbaac50f5143068d3b29847a71fd3c } from "@ember/template-compiler";
const FKLabel = template_e6bbaac50f5143068d3b29847a71fd3c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
