import { template as template_7393d8d24ae54791a5366458ba5e3657 } from "@ember/template-compiler";
const FKText = template_7393d8d24ae54791a5366458ba5e3657(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
