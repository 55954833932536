import { template as template_72608326d6714e1da179e8a737cafebf } from "@ember/template-compiler";
const FKControlMenuContainer = template_72608326d6714e1da179e8a737cafebf(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
